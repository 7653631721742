import { ErrorIdent } from '~/@types/errorIdents';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import { useDialogStore, DialogIdent } from '~/stores/useDialogStore';
import { useFavLists } from '~/stores/useFavLists';
import { useUserContext } from '~/stores/useUserContext';
import type {
  Result,
  PostBody as CreateBody,
} from '@/server/api/[site]/user/favList/createFavList.post';
import type { FavoriteList } from '~/server/transformers/shop/favoriteListTransformer';
import { handleLoadingError } from '~/utils/handleLoadingError';

/**
 * @INFO
 * list and options must be optional
 * because you can have either one but never both for create or edit
 */
type FavListDialogType = {
  list?: FavoriteList;
  options?: {
    items: CreateBody['items'];
    preventNavigateTo?: boolean;
    comingFromMultiselect?: boolean;
  };
};

export function useNewEditFavoriteList() {
  const site = useSiteIdent();
  const errors = ref<ErrorIdent[]>([]);
  const userContext = useUserContext();
  const favListStore = useFavLists();
  const dialogStore = useDialogStore();
  const isLoading = ref(false);
  const { t } = useTrans();
  interface createFavoriteListForm {
    listName: string;
  }

  const dialogData = dialogStore.getDialogData<FavListDialogType>(
    DialogIdent.NEW_EDIT_FAVORITE_LIST,
  );

  const list = dialogData?.list;

  if (!dialogData || dialogData.options) {
    //either i have no dialogData or the dialogData.options to create a new favList
    dialogStore.setHeadline(t('myProducts.dialog.addNewFavList.headline'));
  } else {
    dialogStore.setHeadline(t('myProducts.favLists.settings.renameList'));
  }

  async function onSubmit(data: createFavoriteListForm) {
    isLoading.value = true;
    await createFavoriteList(data);
    isLoading.value = false;
  }

  async function createFavoriteList(formData: createFavoriteListForm) {
    try {
      isLoading.value = true;
      let result;

      if (!list) {
        const createBody = {
          name: formData.listName,
          companyId: userContext?.userData?.customerId,
        } as CreateBody;

        if (dialogData?.options?.items) {
          createBody.items = dialogData.options.items;
        }

        //create
        result = await useSecureSessionPost<Result>(
          `/api/${site}/user/favList/createFavList`,
          createBody,
        );

        if (result) {
          if (dialogData?.options?.comingFromMultiselect) {
            dialogStore.closeDialog(DialogIdent.NEW_EDIT_FAVORITE_LIST);
            dialogStore.openDialog(
              DialogIdent.FAV_LIST_MULTI_SELECT_MOVE_ITEMS,
            );
            dialogStore.setHeadline(
              favListStore.multiselect?.selectedItems?.length +
                ' ' +
                t('favLists.multiSelect.moveItems'),
            );
            return;
          }
          const oldFavLists = favListStore.favoriteLists;
          await favListStore.loadLists(false);
          const newFavList = compareFavListsForChanges(
            oldFavLists,
            favListStore.favoriteLists,
          );

          if (!dialogData?.options?.preventNavigateTo)
            navigateTo(`/productlists/favorites/${newFavList.id}`);
        }
      } else {
        //edit
        const postData = {
          name: formData.listName,
          companyId: userContext?.userData?.customerId,
        };
        result = await useSecureSessionPost<Result>(
          `/api/${site}/user/favList/editFavList`,
          {
            listId: list.id,
            ...postData,
          },
        );

        if (result) await favListStore.loadLists();
      }

      dialogStore.closeDialog(DialogIdent.NEW_EDIT_FAVORITE_LIST);
    } catch (e: any) {
      errors.value = e.data?.data?.errors ?? ErrorIdent.UNKNOWN_ERROR;
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    list,
    onSubmit,
    errors,
    isLoading,
    dialogStore,
  };
}

function compareFavListsForChanges(
  oldFavLists: FavoriteList[],
  newFavLists: FavoriteList[],
): FavoriteList {
  for (const newList of newFavLists) {
    const oldList = oldFavLists.find((list) => list.id === newList.id);
    if (!oldList) {
      return newList;
    }
  }
}
